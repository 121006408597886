import moment from 'moment';
import axios from '../axios';
import * as config from '../config';
import { isMobileDevice } from '../utils';
import { buildFiltersQuery, ParseParams, sourceIDToNumberArray } from './dataset';

export const APIRequestValue = (value) => `'${value}'`;

export const getBrowserID = () => {
  const url = `/v1/cookie/id`;
  return axios.get(url, {});
};

export const getSessionID = () => {
  const url = `/v1/session/id`;
  return axios.get(url, {});
};

export const postClickStreamEvent = async (params) => {
  try {
    if (!localStorage.getItem('browserId')) {
      const browserRes = await getBrowserID();
      localStorage.setItem('browserId', browserRes.data.browser_id);
    }

    if (!sessionStorage.getItem('sessionId')) {
      const sessionRes = await getSessionID();
      sessionStorage.setItem('sessionId', sessionRes.data.session_id);
    }

    const paramsNew = {
      env: config.REACT_APP_BUSINESS_ENV === 'prod' ? 'prd' : 'dev',
      browserid: localStorage.getItem('browserId'),
      sessionid: sessionStorage.getItem('sessionId'),
      event_timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      device: `${isMobileDevice() ? 'mobile' : 'web'}`,
      page_title: document.title,
      page_url: escape(document.URL),
      userid: 'anonymous',
      lat: `${JSON.parse(localStorage.getItem('coords') || '{}')?.lat || ''}`,
      long: `${JSON.parse(localStorage.getItem('coords') || '{}')?.long || ''}`,
      ...params,
    };
    if (navigator.sendBeacon) {
      const blob = new Blob([JSON.stringify(paramsNew)], {
        ...axios.defaults.headers,
      });
      navigator.sendBeacon(config.REACT_APP_CLICKSTREAM_URI, blob);
    } else {
      return axios.post(config.REACT_APP_CLICKSTREAM_URI, { ...paramsNew });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getDataService = (productName, params = {}) => {
  const { method = 'post' } = params;
  const url = `/${productName}`;
  return axios[method](url);
};

export const getSectorList = () => {
  return getDataService('v1/sectors/list', { method: 'get' });
};

export const getMetaData = () => {
  return getDataService('v1/metadata', { method: 'get' });
};

export const getMinistries = () => {
  return getDataService('v1/minstry/list', { method: 'get' });
};

export const getHomePageData = () => {
  return getDataService('v1/static/list', { method: 'get' });
};

export const getCollections = (params = {}) => {
  const defaultParams = { ip_searchterm: "'*'", ip_limit: '10', ip_offset: '0', ip_sector: "'*'", ip_ministry: "'*'" };

  const url = `/v1/collection/list`;
  return axios.post(url, ParseParams({ ...defaultParams, ...params }));
};

export const getStates = (params = {}) => {
  const url = `/getstates`;
  return axios.get(url, { params });
};

export const getDimensionValues = (params = {}) => {
  const url = `/v1/dataset/getdimensionvalues`;
  params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);

  return axios.post(url, params);
};

export const loadYears = async (sourceID, filters = {}) => {
  const datasetID = sourceIDToNumberArray(sourceID);

  if (!datasetID?.length) return false;

  const filterQuery = await buildFiltersQuery({
    datasetID,
    appliedFilters: filters,
  });

  const params = {
    ip_sourceid: datasetID,
    ip_columns: 'Year',
    ip_filter: [],
    ip_search: filterQuery.ip_filter,
    ip_limit: 100000000,
    ip_offset: 0,
    view_name: '',
    ip_orderby: filterQuery.ip_orderby,
  };

  return getDimensionValues(params);
};

export const getSuggestDataset = (params = {}) => {
  const url = `/v1/requestdataset/create`;
  return axios.post(url, params);
};

export const getSuggestedDatasetRequests = (params = {}) => {
  const defaultParams = {
    ip_status: ['Pipeline', 'Pending'],
    ip_ministry: '*',
    ip_limit: 10,
    ip_offset: 0,
    ip_search: '',
  };
  const url = `/v1/requestdataset/list`;
  return axios.post(url, { ...defaultParams, ...params });
};

export const getSuggestedDatasetFilters = (params = {}) => {
  const url = `/v1/requestdataset/filters`;
  return axios.get(url, { params });
};

export const getSuggestedDatasetRequestsLikes = (params = {}) => {
  const url = `/v1/requestdataset/likes`;
  return axios.post(url, params);
};

export const getCollectionsDetails = (params = {}) => {
  const url = `/v1/collection/id`;
  return axios.get(url, { params });
};

export const getDataCatlogueResults = (params = {}) => {
  const url = `/v1/search/catalogue`;
  return axios.get(url, { params });
};

export const getMetaDataCount = (params = {}) => {
  const url = `/v1/metadata`;
  return axios.get(url, { params });
};

export const getColorPallets = () => axios.get(`/v1/viz/colors`);

export const getSiteTotalSummary = async () => {
  const {
    data: { Datsets: datasets = 0, Sectors: sectors = 0, Ministries: ministries = 0, IsError },
  } = await axios.get('v1/totalcount');

  return {
    datasets,
    sectors,
    ministries,
    IsError,
  };
};

export const getTrendingSearchTerms = () => {
  const url = `/v1/trending`;
  return axios.get(url);
};

export const getUnsubscribe = (params = {}) => {
  return axios.get(`/onboarddatasets/unsubscribe?q=${params}`);
};
